.header{
    background-color: var(--dark-bg-color);
    color: white;
    padding: 5px;
    height: 70px;
    /* position: fixed; */
    top: 0;
    z-index: 100;
    width: 450px;
}
.menu{
    margin-bottom: 0;
}
.menu a{
    display: inline;
    padding: 7px 12px;
    margin: 0 5px;
    color: white;
    text-decoration: none;
    transition: 0.5s;
    border-radius: 5px;
}
.menu a:hover{
    background: linear-gradient(180deg, rgb(246, 200, 16) , rgb(246, 121, 5));
    color: black;
}
.menu a.active{
    background: linear-gradient(180deg, rgb(246, 200, 16) , rgb(246, 121, 5));
    color: black;
}
@media only screen and (max-width: 450px) {
    .header {
        width: 100%;
    }
}