.gameCard {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 2px lightgray;
}

.gameCard img {
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;
    width: 100%;
    height: 110px;
}

.gameCard img:hover {
    transform: scale(1.05);
}

.gameCard button {
    width: 100%;
    border: none;
    background: rgb(135, 3, 135);
    font-family: 'MV Boli';
    color: white;
    padding: 8px;
}

.gameCard .title {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gameCard .card-footer {
    padding: 0;
}

.link-underline-hide {
    text-decoration: none;
}