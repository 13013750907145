:root{
  --dark-bg-color: #252525;
}
.custom-heading{
  text-align: center;
  color: #838383b1;
  font-weight: 700;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  letter-spacing: -1px;
}
.custom-card{
  width: 100%;
  padding: 10px;
  border: 1px solid #d3d3d3ba;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px lightgray;
  text-align: center;
}
.custom-button{
  background: rgb(135, 3, 135);
  font-family: 'MV Boli';
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  transition: 0.5s;
}
.custom-button:hover{
  transform: scale(1.02);
}


.ExampleAdSlot{
  height: 250px;
  width: 100px;
}



.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 1000;
  pointer-events: none;
}

.popup-content {
  position: relative;
  height: auto;
  border: 1px solid #888;
  color: #fff;
  border-radius: 5px;
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
  padding: 10px;
  position: absolute;
  pointer-events: auto;
  z-index: 1001;
}

.close-popup {
  outline: none;
  background-color: #ffffff;
  border: 1px solid #000;
  border-radius: 20%;
  color: #000;
  cursor: pointer;
  font-family: arial;
  font-size: 25px;
  font-weight: 700;
  height: 30px;
  line-height: 25px;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  width: 30px;
  z-index: 1003;
}