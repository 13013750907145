.Main_Bg {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: black;
    /* background-image: url('../../assets/images/bg.jpg');
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.8); */
    background-size: cover;
}

.content-section {
    position: relative; 
    width: 450px;
    background-color: white;
    height: 100vh;
    margin: 0 auto;
    z-index: 2; 
    overflow: scroll;
}
/* width */
.content-section::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
@media only screen and (max-width: 450px) {
    .content-section {
        width: 100%;
    }
}